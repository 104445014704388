import React, { useState, useEffect } from "react";
import "../../src/styles/SongRecom.css";
import Card from "../components/card";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";

const songs = [
  {
    title: "İçimde Ölen Biri Var",
    artist: "Ahmet Kaya",
    trackUri: "610tgZLE35b52nnzaYcnR4",
  },
  {
    title: "Geçmesin Günümüz",
    artist: "Dedüblüman",
    trackUri: "1uXQ6iKio7QGEKz8KFogay",
  },
  {
    title: "Dağlara Mı Yazdın",
    artist: "Songül Güner",
    trackUri: "0AJSsSxbwNvCOKZAAp9by2",
  },
];

const SongRecommendationPage = () => {
  const [currentIndex, setCurrentIndex] = useState(songs.length - 1);
  const [currentTrackUri, setCurrentTrackUri] = useState(null);
  const [myPlaylist, setMyPlaylist] = useState([]); 
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    setTimeout(() => setLoading(false), 9000); 
  }, []);

  const swiped = (direction, index) => {
    if (direction === "right") {
      const likedSong = songs[index];
      setMyPlaylist((prevPlaylist) => {
        const updatedPlaylist = [...prevPlaylist, likedSong];
        localStorage.setItem("myPlaylist", JSON.stringify(updatedPlaylist));
        return updatedPlaylist;
      });
    }
    setCurrentIndex(index - 1);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen background deneme">
      {loading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      
      {!loading && (
        <>
          <Navbar />
          <div className="mb-10 mt-24 text-[#f4f2f2] tracking-wide font-light text-2xl underline decoration-wavy underline-offset-8">
            Duygu durumu: <span className="font-semibold italic">Derbeder Hüso</span>
          </div>
          <div className="cardContainer flex justify-center items-center relative w-80 h-3/4 max-w-md">
            <div className="flex flex-row">
              <div className="mt-[-9%] mr-96">
                <svg
                  width={450}
                  viewBox="0 0 48 48"
                  version="1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#e01414fa"
                >
                  <path
                    fill="#2d2d2d32"
                    d="M34,9c-4.2,0-7.9,2.1-10,5.4C21.9,11.1,18.2,9,14,9C7.4,9,2,14.4,2,21c0,11.9,22,24,22,24s22-12,22-24 C46,14.4,40.6,9,34,9z"
                  ></path>
                  <rect
                    x="22"
                    y="-2.9"
                    transform="matrix(.707 -.707 .707 .707 -9.941 24)"
                    fill="#23232361"
                    width="4"
                    height="53.7"
                  ></rect>
                </svg>
              </div>
              <div className="mt-[-9%] ml-44">
                <svg
                  width={450}
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#2d2d2d32"
                >
                  <path
                    d="M725.333333 192c-89.6 0-168.533333 44.8-213.333333 115.2C467.2 236.8 388.266667 192 298.666667 192 157.866667 192 42.666667 307.2 42.666667 448c0 253.866667 469.333333 512 469.333333 512s469.333333-256 469.333333-512c0-140.8-115.2-256-256-256z"
                    fill="#2d2d2d32"
                  ></path>
                </svg>
              </div>
            </div>
            {songs.map((song, index) => (
              <Card
                key={song.title}
                song={song}
                onSwipe={(dir) => swiped(dir, index)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SongRecommendationPage;
