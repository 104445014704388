import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import "../../src/styles/Playlist.css";

const MyPlaylist = ({ playlist }) => {
  return (
    <div className="flex flex-col min-h-screen background-playlist text-white p-4">
      <Navbar />

      <div className="mt-20 mb-2 mx-auto items-center">
        <h2 className="text-3xl font-semibold mb-6">My Playlist</h2>
      </div>
      <ul className="w-full space-y-4">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 w-full">
          {playlist.length > 0 ? (
            playlist.map((song, index) => (
              <li key={index} className="bg-[#dedcdc68] p-4 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold">{song.title}</h3>
                <p className="text-[#202020] font-semibold">{song.artist}</p>
                <a
                  href={`https://open.spotify.com/track/${song.trackUri}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#286272] underline underline-offset-1 font-semibold"
                >
                  Spotify’da Dinle 
                </a>
                <span>🎧</span>
              </li>
            ))
          ) : (
            <p>Henüz hiçbir şarkı eklenmedi.</p>
          )}
        </div>
      </ul>
    </div>
  );
};

export default MyPlaylist;
