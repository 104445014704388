// spotifyService.js
import axios from 'axios';

const CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
const REDIRECT_URI = process.env.REACT_APP_SPOTIFY_REDIRECT_URI;
const AUTH_URL = 'https://accounts.spotify.com/authorize';
const TOKEN_URL = 'https://accounts.spotify.com/api/token';

// Otorizasyon URL'si
export const getAuthUrl = () => {
  return `${AUTH_URL}?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&scope=user-library-read user-modify-playback-state`;
};

// İlk token alma işlemi
export const getToken = async (code) => {
  const params = new URLSearchParams();
  params.append('grant_type', 'authorization_code');
  params.append('code', code);
  params.append('redirect_uri', REDIRECT_URI);

  try {
    const response = await axios.post(TOKEN_URL, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET}`)}`,
      },
    });
    localStorage.setItem('spotify_access_token', response.data.access_token);
    localStorage.setItem('spotify_refresh_token', response.data.refresh_token);
    return response.data.access_token;
  } catch (error) {
    console.error("Token alma hatası:", error.response.data);
    throw error;
  }
};

export const resumeSong = async (accessToken) => {
  try {
    await fetch("https://api.spotify.com/v1/me/player/play", {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (error) {
    console.error("Şarkıyı devam ettirme hatası:", error);
  }
};

export const pauseSong = async (accessToken) => {
  if (!accessToken) {
    console.error("Token bulunamadı, kullanıcı giriş yapmalı.");
    return;
  }

  try {
    await fetch("https://api.spotify.com/v1/me/player/pause", {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (error) {
    console.error("Şarkıyı durdurma hatası:", error);
  }
};


// Şarkıyı çalma işlemi
export const playSong = async (trackUri, accessToken) => {
  try {
    const response = await fetch(`https://api.spotify.com/v1/me/player/play`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ uris: [trackUri] }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Şarkı çalma hatası:", errorData);
      if (errorData.error && errorData.error.status === 403) {
        console.error("Permission missing: Ensure that user-modify-playback-state scope is added.");
      }
    }
  } catch (error) {
    console.error("Play song request failed:", error);
  }
};
