import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import SongRecommendationPage from "./pages/SongRecommendationPage";
import Callback from "./pages/Callback";
import MyPlaylist from "./pages/MyPlaylist";

const App = () => {
  const initialPlaylist = JSON.parse(localStorage.getItem("myPlaylist")) || [];
  const [myPlaylist, setMyPlaylist] = useState(initialPlaylist);

  useEffect(() => {
    // myPlaylist her güncellendiğinde localStorage'a kaydet ve konsola yazdır
    localStorage.setItem("myPlaylist", JSON.stringify(myPlaylist));
    console.log(
      "localStorage myPlaylist:",
      JSON.parse(localStorage.getItem("myPlaylist"))
    );
  }, [myPlaylist]);

  return (
    <div className="App">
      <script src="https://sdk.scdn.co/spotify-player.js"></script>

      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/callback" element={<Callback />} />
          <Route
            path="/songs"
            element={
              <SongRecommendationPage
                myPlaylist={myPlaylist}
                setMyPlaylist={setMyPlaylist}
              />
            }
          />
          <Route
            path="/myplaylist"
            element={<MyPlaylist playlist={myPlaylist} />}
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
