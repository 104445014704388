import React from 'react';
import '../styles/Loader.css';

const Loader = () => {
    return (
      <div className="loader-background">
        <div className="card">
          <div className="loader">
            <p>Yüzler okunuyor, arşive alınıyor…</p>
            <div className="words">
              <span className="word">Hiddetli Halil</span>
              <span className="word">Şeker Gibi Şükrü</span>
              <span className="word">Derbeder Hüso</span>
              <span className="word">Delifişek Dursun</span>
              <span className="word">Bön Bön Bakan Burhan</span>
              <span className="word">Panik Hasan</span>
              <span className="word">Rahat Rıza</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default Loader;