import React, { useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import Webcam from "react-webcam";
import '../styles/PhotoCapture.css';
import { ImCamera  } from "react-icons/im";

const PhotoCapture = () => {
  const webcamRef = useRef(null); // Webcam referansı
  const [photo, setPhoto] = useState(null); // Çekilen fotoğrafı saklayacak state
  const [isCameraActive, setIsCameraActive] = useState(false); // Kameranın aktif olup olmadığını kontrol ediyoruz
  const navigate = useNavigate(); 

  // Fotoğraf çekme işlemi
  const capturePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot(); // Fotoğrafı base64 formatında alıyoruz
    setPhoto(imageSrc); // Fotoğrafı state'e kaydediyoruz
    setIsCameraActive(false); // Kamerayı kapatıyoruz
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen background ">
      {/* Avatar veya çekilen fotoğraf */}
      <div className="relative">
        {!photo && !isCameraActive && (
          <img
            src="/assets/avatar1.png"
            alt="Avatar"
            className="w-52 h-72 object-cover rounded-full border-8 border-x-stone-100"
          />
        )}

        {isCameraActive && (
          <div className="relative">
            <Webcam
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              className="w-full max-w-md h-72 object-cover rounded-[20%] "
            />
            {/* Oval çerçeve */}
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-52 h-72 rounded-full border-dashed border-4 border-x-stone-100 animate-pulse-border"></div>
            </div>
          </div>
        )}

        {photo && (
          <div className="flex items-center justify-center">
            <img
              src={photo}
              alt="Captured"
              className="w-52 h-72 object-cover rounded-full border-8 border-x-stone-100"
            />
          </div>
        )}
      </div>

      {/* Butonlar */}
      {!isCameraActive && !photo && (
        <button
          onClick={() => setIsCameraActive(true)}
          className="mt-4 p-2 bg-[#170408] text-white rounded-lg shadow-lg hover:bg-[#000000d0] transition-all duration-300 border-none outline-none"
        >
          Start Camera
        </button>
      )}

      {isCameraActive && (
        <button
          onClick={capturePhoto}
          className="mt-4 p-2 bg-[#fc466b] text-white rounded-lg shadow-lg hover:bg-[#fc466ac6] transition-all duration-300 flex justify-center items-center"
        >
          <ImCamera />
          <p className="ml-1">Take Photo</p>
        </button>
      )}

      <div className="flex space-x-4">
      {photo && (
        <button
          onClick={() => setPhoto(null)}
          className="mt-4 p-2 bg-[#fc466b] text-white rounded-lg shadow-lg hover:bg-[#fc466ac6] transition-all duration-300"
        >
          Retake Photo
        </button>
      )}

{photo && (
        <button
          onClick={() => navigate('/songs')}
          className="mt-4 p-2 bg-[#170408] text-white rounded-lg shadow-lg hover:bg-[#000000d0] transition-all duration-300"
        >
          It's My Feeling
        </button>
      )}
      </div>
    </div>
  );
};

export default PhotoCapture;
