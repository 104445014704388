import React from 'react'
import PhotoCapture from '../components/PhotoCapture';
import Navbar from '../components/Navbar';

const HomePage = () => {
  return (
    <div>
      <Navbar />
      <PhotoCapture />
    </div>
  )
}

export default HomePage
