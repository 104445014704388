// Card.jsx
import React, { useState } from "react";
import TinderCard from "react-tinder-card";
import { animated } from "@react-spring/web";
import { IoPauseOutline } from "react-icons/io5";
import "../../src/styles/SongRecom.css";
import { playSong, pauseSong, resumeSong } from "../services/spotifyService"; // playSong fonksiyonunu import et

const Card = ({ song, onSwipe, onCardLeftScreen }) => {
  const accessToken = localStorage.getItem("spotify_access_token");
  const [currentTrackUri, setCurrentTrackUri] = useState(null); // Çalınan şarkıyı takip etmek için

  const handlePlayPauseToggle = (trackUri) => {
    if (accessToken) {
      if (currentTrackUri === trackUri) {
        resumeSong(accessToken); // Aynı şarkıysa kaldığı yerden devam et
      } else {
        pauseSong(accessToken); // Yeni şarkıya geçileceği için önce durdur
        playSong(trackUri, accessToken)
          .then(() => setCurrentTrackUri(trackUri)) // Yeni şarkıyı başlat ve URI güncelle
          .catch((error) => console.error("Şarkı çalma hatası:", error));
      }
    } else {
      console.error("Token bulunamadı, önce giriş yapmalısınız.");
    }
  };

  return (
    <TinderCard
      className="absolute w-full md:w-[700px] h-full rounded-3xl overflow-hidden m-auto"
      onSwipe={(dir) => {
        onSwipe(dir);
      }}
      onCardLeftScreen={onCardLeftScreen}
      preventSwipe={["up", "down"]}
    >
      <animated.div className="h-auto rounded-full p-6 relative card-back w-auto m-auto z-auto">
        <iframe
          style={{ borderRadius: "60px" }}
          src={`https://open.spotify.com/embed/track/${song.trackUri}?utm_source=generator`}
          width="100%"
          height="352"
          frameborder="0"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
          allowFullScreen
        ></iframe>
      </animated.div>
    </TinderCard>
  );
};
export default Card;
