import React, { useState, useEffect } from "react";
import { getAuthUrl } from "../services/spotifyService";
import { FiMenu, FiX } from "react-icons/fi"; // Hamburger menü ikonları için

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem("spotify_access_token")
  );
  const [menuOpen, setMenuOpen] = useState(false); // Menü durumunu tutuyor

  const handleLogin = () => {
    window.location.href = getAuthUrl();
  };

  const handleLogout = () => {
    localStorage.removeItem("spotify_access_token");
    setIsLoggedIn(false);
  };

  return (
    <div className="bg-[#000000b3] px-8 rounded-full w-[95%] mx-auto fixed top-2 left-1/2 transform -translate-x-1/2 z-50 flex justify-between items-center">
      <a href="/#/" className="text-white px-6 py-2 rounded-lg transition">
        <img
          src="./assets/logoson.png "
          alt="Logo"
          className="rounded-full w-28 ml-[-20%]"
        />
      </a>

      {/* Hamburger Menü İkonu (Mobilde Görünür) */}
      <div className="flex md:hidden">
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className="text-white focus:outline-none"
        >
          {menuOpen ? <FiX size={28} /> : <FiMenu size={28} />}
        </button>
      </div>

      {/* Menü İçeriği */}
      <div
        className={`${
          menuOpen ? "translate-x-0 opacity-100 flex" : "translate-x-full opacity-0 "
        } flex-col md:flex md:flex-row items-center md:items-end w-48 md:w-auto md:static absolute top-full right-0 mt-2 rounded-xl bg-[#000000b3] md:bg-transparent transition-all duration-300 ease-out transform p-4 md:p-0 z-40 md:opacity-100 md:translate-x-0`}
      >
        <a
          href="/"
          className="text-white px-6 py-2 rounded-lg my-auto hover:bg-[#fffcfcd5] hover:text-black transition font-semibold mr-2 md:hidden"
        >
          Anasayfa
        </a>
        <a  
          href="/myplaylist"
          className="text-white px-6 py-2 rounded-lg my-auto hover:bg-[#fffcfcd5] hover:text-black transition font-semibold mr-2"
        >
          My Playlist
        </a>
        {isLoggedIn ? (
          <a
            onClick={handleLogout}
            className="text-black px-6 py-2 rounded-lg my-auto bg-[#fefffcd5] hover:bg-[#ff590097] hover:text-white transition font-semibold cursor-pointer"
          >
            Çıkış Yap
          </a>
        ) : (
          <a
            onClick={handleLogin}
            className="text-black px-6 py-2 rounded-lg my-auto bg-[#fefffcd5] hover:bg-[#5eff0067] hover:text-white transition font-semibold cursor-pointer"
          >
            Spotify ile Giriş Yap
          </a>
        )}
      </div>
    </div>
  );
};

export default Navbar;
