import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getToken } from "../services/spotifyService";

const Callback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");

    if (code) {
      getToken(code)
        .then(() => {
          navigate("/songs"); // Başarıyla token alındığında yönlendirme
        })
        .catch((error) => {
          console.error("Token alma hatası:", error);
          navigate("/"); // Hata durumunda ana sayfaya dön
        });
    } else {
      navigate("/"); // Eğer code yoksa ana sayfaya geri yönlendir
    }
  }, [location, navigate]);

  return <div>Giriş yapılıyor, lütfen bekleyin...</div>;
};

export default Callback;
